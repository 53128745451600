var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "w-full" }, [
      _c("div", { staticClass: "form-group" }, [
        _c("label", { staticClass: "control-label vs-input--label" }, [
          _vm._v(_vm._s(_vm.$t("marketing_place.payment_type"))),
        ]),
        _c(
          "div",
          { staticClass: "mb-1 mt-2" },
          _vm._l(_vm.paymentTypeList, function (item) {
            return _c(
              "vs-radio",
              {
                key: item.key,
                staticClass: "mr-2",
                attrs: {
                  "vs-name": "paymentType",
                  label: item.label,
                  "vs-value": item.key,
                },
                model: {
                  value: _vm.model.paymentType,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "paymentType", $$v)
                  },
                  expression: "model.paymentType",
                },
              },
              [_vm._v(_vm._s(item.label))]
            )
          }),
          1
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "w-full pt-5" },
      [
        _c("vs-divider"),
        _c("div", { staticClass: "flex items-center justify-between" }, [
          _c("span", { staticClass: "text-md" }, [
            _vm._v(_vm._s(_vm.$t("marketing_place.total_value_"))),
          ]),
          _c("span", { staticClass: "font-bold" }, [
            _vm._v(_vm._s(_vm.formatValue(_vm.valueTotal))),
          ]),
        ]),
        _c("vs-divider"),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "flex mt-4 mb-4 justify-end" },
      [
        _c(
          "vs-button",
          {
            attrs: {
              disabled: !_vm.validateForm,
              type: "filled",
              icon: "money",
            },
            on: {
              click: function ($event) {
                return _vm.confirmPayment()
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("action.confirm_payment")) + " ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }