var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vs-popup",
    {
      ref: "vspopup-per",
      attrs: {
        title: _vm.$t("product.title"),
        active: _vm.visible,
        id: "popup-entity",
      },
      on: {
        "update:active": function ($event) {
          _vm.visible = $event
        },
        close: _vm.closePopup,
      },
    },
    [
      _vm.visible
        ? _c("Grid", {
            ref: "grid-popup",
            attrs: {
              service: _vm.service,
              route_name: "product",
              route_grid_path: "/sales/grid",
              hide_actions: "true",
              order_column: "name",
              delegate: {
                itemSelected: function () {
                  return _vm.handleSelection
                },
              },
              selectObject: "true",
              "request-params": _vm.filtersGrid,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "gridThead",
                  fn: function () {
                    return [
                      _c("vs-th", { key: "id" }, [_vm._v("ID")]),
                      _c("vs-th", { key: "name" }, [
                        _vm._v(_vm._s(_vm.$t("product.name"))),
                      ]),
                      _c("vs-th", { key: "price_fmt" }, [
                        _vm._v(_vm._s(_vm.$t("product.price"))),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "gridData",
                  fn: function (props) {
                    return _vm._l(props.data, function (tr, indextr) {
                      return _c(
                        "vs-tr",
                        { key: indextr, attrs: { data: tr } },
                        [
                          _c("vs-td", { key: "id", attrs: { width: "5%" } }, [
                            _c("div", {
                              directives: [
                                {
                                  name: "html-safe",
                                  rawName: "v-html-safe",
                                  value: tr.id,
                                  expression: "tr.id",
                                },
                              ],
                            }),
                          ]),
                          _c(
                            "vs-td",
                            { key: "name", attrs: { width: "65%" } },
                            [
                              _c("div", {
                                directives: [
                                  {
                                    name: "html-safe",
                                    rawName: "v-html-safe",
                                    value: tr.name,
                                    expression: "tr.name",
                                  },
                                ],
                              }),
                            ]
                          ),
                          _c(
                            "vs-td",
                            { key: "price", attrs: { width: "30%" } },
                            [
                              _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatValue(_vm._.get(tr, "price"))
                                    )
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    })
                  },
                },
              ],
              null,
              false,
              2706035617
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }