<template>
  <div>
    <vx-card class="mb-4">
      <div class="w-full flex gap-2 items-center justify-end pt-4">
        <CartDropDown :cartItems="cart.items" @updateCartHandling="updateCartHandling"/>
        <CartResumeSideBar :cartItems="cart.items" @updateCartHandling="updateCartHandling"/>
      </div>
      <vs-tabs :key="tabsCorporateSalesKey">
        <vs-tab :label="$t('product.title')">
          <ProductGridCardView
            :corporate_sale="true"
            @updateCartHandling="updateCartHandling" />

          <!--
          <div class="w-full gap-2">
            <vx-input-group>
              <vs-input
                id="product_name"
                readonly
                class="w-full required"
                :label="$t('product.name')"
                v-model="model.name"/>
              <span class="text-danger text-sm" v-show="errors.has('product_name')">{{errors.first('product_name')}}</span>
              <template slot="append">
                <div class="append-text btn-addon flex items-end">
                  <vs-button
                    color="primary"
                    type="border"
                    icon="search"
                    :title="$t('buscar')"
                    @click="showProductPopup = true"></vs-button>
                </div>
              </template>
            </vx-input-group>
            <ProductPopup
              :visible="showProductPopup"
              @selected="addProductFromPopup"
              @close="closeProductPopup"/>
          </div>
          <div class="w-full flex gap-2 mt-5 justify-end">
            <vs-button
              :disabled="!validateForm"
              @click="createOrUpdate">
              {{ $t('action.save') }}
            </vs-button>
          </div>
          -->
        </vs-tab>
        <vs-tab :label="$t('marketing_place.purchase_summary')" v-if="isOpenCart">
          <CartResume :cartItems="cart.items" @updateCartHandling="updateCartHandling" :removeItemEnable="true"/>
        </vs-tab>
        <!--
        <vs-tab :label="$t('marketing_place.interested')" v-if="isOpenCart">
          <FormParticipant :cart_id="cartId"/>
        </vs-tab>
        -->
        <vs-tab :label="$t('marketing_place.payment')" v-if="isOpenCart">
          <FormPayment :cart_id="cartId" :valueTotal="valueTotal" @updateCartHandling="updateCartHandlingOfPayment"></FormPayment>
        </vs-tab>
      </vs-tabs>
    </vx-card>
  </div>
</template>

<script>

import standard from '@/services/standard'

import { isAdmin } from '@/util/Util'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import SelectSuggestion from '@/components/SelectSuggestion.vue'
import ProductPopup from './../product/ProductPopup.vue'
import ProductGridCardView from './ProductGridCardView'
import FormParticipant from './FormParticipant'
import FormPayment from './FormPayment'
import CartDropDown from './CartDropDown'
import CartResume from './CartResume'
import CartResumeSideBar from './CartResumeSideBar'

import CartService from '@/services/api/CartService'


export default {
  directives: {

  },
  components: {
    ValidationProvider,
    ValidationObserver,
    SelectSuggestion,
    ProductPopup,
    ProductGridCardView,
    FormParticipant,
    FormPayment,
    CartDropDown,
    CartResume,
    CartResumeSideBar
  },
  props: {

  },

  data: () => ({
    service: null,
    showProductPopup: false,
    cartId: null,
    tabsCorporateSalesKey: 0,
    cart: {
      items: []
    },

  }),
  computed: {
    validateForm() {
      return true
    },
    isOpenCart() {
      return !_.isNil(this.cartId)
    },
    valueTotal () {
        let total = 0
       this.cart.items.forEach((item) => {
            total += (item.quantity*item.price)
        });
        return total
    },
  },
  beforeMount() {
    this.service = CartService.build(this.$vs)
  },
  mounted() {
    this.loadData()
  },

  methods: {
    loadData() {
      this.$vs.loading()
      const isCorporateSale = true
      this.service.getOpenCart(isCorporateSale).then(
        response => {
          this.cartId = _.get(response, 'cart_id')
          if(response){
            this.cart = response
          }
        },
        error => {
          this.$vs.loading.close()
        }
      ).finally(() => {this.$vs.loading.close()})
    },
    updateCartHandling(item){
      this.loadData()
    },
    updateCartHandlingOfPayment(){
      this.cartId = null;
      this.cart.items = [];
      this.tabsCorporateSalesKey++
    },
    goBack() {

    },
    isAdmin() {
      return isAdmin()
    }
  }
}
</script>

<style>

</style>
