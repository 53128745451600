<template>
  <div>
    <div class="w-full">
      <div class="form-group">
        <label class="control-label vs-input--label">{{$t('marketing_place.payment_type')}}</label>
        <div class="mb-1 mt-2">
          <vs-radio v-model="model.paymentType"
                    vs-name="paymentType"
                    v-for="item in paymentTypeList"
                    :key="item.key"
                    :label="item.label"
                    :vs-value="item.key"
                    class="mr-2">{{item.label}}</vs-radio>
        </div>
      </div>
    </div>
    <div class="w-full pt-5">
      <vs-divider />
      <div class="flex items-center justify-between">
        <span class="text-md">{{$t('marketing_place.total_value_')}}</span><span class="font-bold">{{formatValue(valueTotal)}}</span>
      </div>
      <vs-divider />
    </div>

    <div class="flex mt-4 mb-4 justify-end">
      <vs-button
        :disabled="!validateForm"
        type="filled"
        icon="money"
        @click="confirmPayment()">{{ $t('action.confirm_payment') }}
      </vs-button>
    </div>
  </div>
</template>

<script>

import standard from '@/services/standard'

import { isAdmin } from '@/util/Util'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { currencyFormatter } from '@/util/Util'

import CartService from '@/services/api/CartService'


export default {
  directives: {

  },
  components: {
    ValidationProvider,
    ValidationObserver,

  },
  props: {
    cart_id: {
      default: null,
      type: Number
    },
    valueTotal: {
      default: 0,
      type: Number,
      required: true
    },
  },

  data: () => ({
    service: null,
    paymentTypeList: [],
    model: {
      status : '',
      paymentType : '',
    }
  }),
  computed: {
    validateForm() {
      return true//!this.isEmpty(this.model.name)
    }
  },
  beforeMount() {
    this.service = CartService.build(this.$vs)
  },
  mounted() {
    this.loadData()
  },

  methods: {
    requiredClass(whichData) {
      return !this.isEmpty(whichData) ? 'w-full' : 'w-full required'
    },
    formatValue(value){
      return currencyFormatter(value)
    },
    loadData() {
      this.loadPaymentType()

    },
    loadPaymentType(){
      this.$vs.loading()
      this.service.getPaymentTypes().then(
        data => {
          this.paymentTypeList = data
        },
        error => {
          this.showErrors(this.$validator, error)
        }
      ).finally(() => { this.$vs.loading.close() })
    },
    confirmPayment() {
      this.$vs.loading()

      const modelData = {
        status: this.model.status,
        payment_type: this.model.paymentType,
      }

      this.service.payCart(this.cart_id, {form : modelData}).then(
        data => {
          this.updateCartHandling()
          this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
        },
        error => {
          this.showErrors(this.$validator, error)
          this.notifyError(
            this.$vs,
            this.$t('nao-foi-possivel-realizar-esta-operacao')
          )
        }
      ).finally(() => { this.$vs.loading.close() })
    },
    updateCartHandling(){
      //TODO impleemntar para limpar cart in tela
      this.$emit('updateCartHandling')
    },
    isAdmin() {
      return isAdmin()
    }
  }
}
</script>

<style>

</style>
